export function isString(value: string | unknown): value is string {
  return typeof value === 'string';
}
export function assertString(value: string | unknown): asserts value is string {
  if (!isString(value)) {
    if (value === null) {
      throw new Error('Expected type string, got null.');
    }
    console.warn('Expected type string, got ' + typeof value + '.', value);
    throw new Error('Expected type string, got ' + typeof value + '.');
  }
}

export function validString(value: string | unknown): string {
  assertString(value);
  return value;
}
