import { Injectable } from "@angular/core";
import { combineLatest, firstValueFrom, Observable, of } from "rxjs";
import {
  TenantService,
  TenantWithDatasourcesCount,
} from "../api/tenant.service";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { ErrorHandlerService } from "../error-handler.service";
import { TenantsStoreService } from "src/app/_store/tenants.store.service";
import { SecuritySubscriptionPublic } from "../api/security.service";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { UtilService } from "../util.service";
import { CurrentTenantService } from "../current-tenant.service";
import { isNotNull } from "src/../../src/types/not-null";
import { TenantId } from "src/../../src/types/tenant-id";
import { SubscriptionsRepositoryService } from "./subscriptions-repository.service";
import { Uuid } from "src/../../src/types/uuid";
import { TenantWithPublicSubscription as _TenantWithPublicSubscription } from "../../../../../src/types/api/tenant/tenant";

export type TenantWithPublicSubscription = _TenantWithPublicSubscription;

@Injectable({
  providedIn: "root",
})
export class TenantRepositoryService {
  public readonly $tenants: Observable<TenantWithPublicSubscription[]>;
  public readonly $currentTenant: Observable<TenantWithPublicSubscription | null>;

  constructor(
    private _tenantsStoreService: TenantsStoreService,
    private _tenantService: TenantService,
    private _currentTenantService: CurrentTenantService,
    private _authUserStoreService: AuthUserReadonlyRepositoryService,
    private _subscriptionsRepositoryService: SubscriptionsRepositoryService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    const _$tenants: Observable<TenantWithDatasourcesCount[]> =
      this._tenantsStoreService.observable.pipe(
        map((tenants) => UtilService.sortBy(tenants, (t) => t.name))
      );

    this.$tenants = combineLatest([
      _$tenants,
      this._subscriptionsRepositoryService.$observablePublic,
    ])
      .pipe(
        map(([tenants, subscriptions]) => {
          return this.attachSubscriptions(tenants, subscriptions);
        })
      )
      .pipe(shareReplay(1));

    this.$currentTenant = combineLatest([
      this.$tenants,
      this._currentTenantService.observableId,
    ])
      .pipe(
        map(([tenants, tenantId]): TenantWithPublicSubscription | null => {
          return tenantId
            ? tenants.find((tenant) => tenant.id === tenantId) ?? null
            : null;
        })
      )
      .pipe(shareReplay(1));
  }

  private attachSubscriptions(
    tenants: TenantWithDatasourcesCount[],
    subscriptions: SecuritySubscriptionPublic[]
  ): TenantWithPublicSubscription[] {
    return tenants.map(
      (tenant: TenantWithDatasourcesCount): TenantWithPublicSubscription => {
        return {
          id: tenant.id,
          name: tenant.name,
          createdAt: tenant.createdAt,
          active: tenant.active,
          defaultTimeZone: tenant.defaultTimeZone,
          defaultCurrency: tenant.defaultCurrency,
          defaultLanguage: tenant.defaultLanguage,
          brandColor: tenant.brandColor,
          brandLogo: tenant.brandLogo,
          datasourcesCount: tenant.datasourcesCount,
          subscription:
            subscriptions.find((sub) => sub.tenants.includes(tenant.id)) ??
            null,
        };
      }
    );
  }

  public load(
    forceReload: boolean = false,
    withSubscriptions: boolean = false
  ): Observable<boolean> {
    const observables: Observable<boolean>[] = [];

    const user = this._authUserStoreService.current;
    if (forceReload || !this._tenantsStoreService.isLoaded()) {
      observables.push(
        (user?.isAdmin()
          ? this._tenantService.getAllTenants(true)
          : this._tenantService.getMyTenants(true)
        )
          .pipe(
            tap((tenants: TenantWithDatasourcesCount[]) => {
              this._tenantsStoreService.updateList(tenants);
            })
          )
          .pipe(map(() => true))
          .pipe(
            catchError((error) => {
              this._errorHandlerService.handle(error);

              return of(false);
            })
          )
      );
    }

    if (withSubscriptions) {
      observables.push(
        this._subscriptionsRepositoryService.loadPublicSubscriptions(
          forceReload
        )
      );
    }

    return observables.length === 0
      ? of(true)
      : combineLatest(observables).pipe(
          map((success) => success.every((s) => s))
        );
  }

  public async create(data: {
    subscriptionId: Uuid;
    name: string;
    active: boolean;
  }): Promise<TenantWithDatasourcesCount> {
    const tenant: TenantWithDatasourcesCount = await firstValueFrom(
      this._tenantService.createTenant(data)
    );
    this._tenantsStoreService.addItem(tenant);

    await this._subscriptionsRepositoryService.refreshLoaded();
    return tenant;
  }

  public activate(id: TenantId): Promise<TenantWithDatasourcesCount> {
    return firstValueFrom(this._tenantService.activateTenant(id)).then(
      (tenant: TenantWithDatasourcesCount) => {
        this._tenantsStoreService.updateItemById(tenant);
        return tenant;
      }
    );
  }

  public deactivate(id: TenantId): Promise<TenantWithDatasourcesCount> {
    return firstValueFrom(this._tenantService.deactivateTenant(id)).then(
      (tenant: TenantWithDatasourcesCount) => {
        this._tenantsStoreService.updateItemById(tenant);
        return tenant;
      }
    );
  }

  public delete(id: TenantId): Promise<void> {
    return firstValueFrom(this._tenantService.removeTenant(id)).then(() => {
      this._tenantsStoreService.removeItemById(id);
    });
  }

  public update(
    data: {
      name?: string;
      active?: boolean;
      defaultTimeZone?: string;
      defaultCurrency?: string;
      defaultLanguage?: string;
      brandColor?: string;
      brandLogo?: string | null;
    },
    tenantId: TenantId
  ): Promise<TenantWithDatasourcesCount> {
    return firstValueFrom(
      this._tenantService.updateTenant(tenantId, data)
    ).then((tenant: TenantWithDatasourcesCount) => {
      this._tenantsStoreService.updateItemById(tenant);
      if (tenant.id === this._currentTenantService.currentTenantId) {
        this._currentTenantService.updateTenant(tenant);
      }
      return tenant;
    });
  }

  public currentFindMultiple(tenantIds: string[]) {
    const tenants = this.current;

    return tenantIds
      .map((tenantId) => tenants.find((t) => t.id === tenantId) ?? null)
      .filter(isNotNull);
  }

  public get current(): TenantWithPublicSubscription[] {
    return this.attachSubscriptions(
      UtilService.sortBy(this._tenantsStoreService.current, (t) => t.name),
      this._subscriptionsRepositoryService.currentPublic
    );
  }
}
