import { assertStringNotEmpty } from './string-not-empty';

// DO NOT MODIFY THIS COMMENT autogenerated start
export const StringScopes: {
  readonly ALL: 'all';
  readonly TENANT_INDEPENDENT: 'tenant_independent';
  readonly OFFLINE: 'offline';
  readonly SECURITY_ALL: 'security.all';
  readonly SECURITY_CLIENTS_ALL: 'security.clients.all';
  readonly SECURITY_CLIENTS_SHOW: 'security.clients.show';
  readonly SECURITY_CLIENTS_CREATE: 'security.clients.create';
  readonly SECURITY_CLIENTS_UPDATE: 'security.clients.update';
  readonly SECURITY_CLIENTS_DELETE: 'security.clients.delete';
  readonly SECURITY_CLIENTS_SHOW_SECRETS: 'security.clients.show_secrets';
  readonly SECURITY_OAUTH_CLIENTS_ALL: 'security.oauth_clients.all';
  readonly SECURITY_OAUTH_CLIENTS_SHOW: 'security.oauth_clients.show';
  readonly SECURITY_OAUTH_CLIENTS_CREATE: 'security.oauth_clients.create';
  readonly SECURITY_OAUTH_CLIENTS_UPDATE: 'security.oauth_clients.update';
  readonly SECURITY_OAUTH_CLIENTS_DELETE: 'security.oauth_clients.delete';
  readonly SECURITY_OAUTH_CLIENTS_SHOW_SECRETS: 'security.oauth_clients.show_secrets';
  readonly SECURITY_TENANTS_REGISTER: 'security.tenants.register';
  readonly SECURITY_TENANTS_DELETE: 'security.tenants.delete';
  readonly SECURITY_USERS_ALL: 'security.users.all';
  readonly SECURITY_USERS_SHOW: 'security.users.show';
  readonly SECURITY_USERS_CREATE: 'security.users.create';
  readonly SECURITY_USERS_UPDATE: 'security.users.update';
  readonly SECURITY_USERS_DELETE: 'security.users.delete';
  readonly SECURITY_GROUPS_ALL: 'security.groups.all';
  readonly SECURITY_GROUPS_SHOW: 'security.groups.show';
  readonly SECURITY_GROUPS_CREATE: 'security.groups.create';
  readonly SECURITY_GROUPS_UPDATE: 'security.groups.update';
  readonly SECURITY_GROUPS_DELETE: 'security.groups.delete';
  readonly SECURITY_SUBSCRIPTIONS_ALL: 'security.subscriptions.all';
  readonly SECURITY_SUBSCRIPTIONS_SHOW: 'security.subscriptions.show';
  readonly SECURITY_SUBSCRIPTIONS_CREATE: 'security.subscriptions.create';
  readonly SECURITY_SUBSCRIPTIONS_UPDATE: 'security.subscriptions.update';
  readonly SECURITY_SUBSCRIPTIONS_DELETE: 'security.subscriptions.delete';
  readonly TENANT_ALL: 'tenant.all';
  readonly TENANT_CREDENTIALS_ALL: 'tenant.credentials.all';
  readonly TENANT_CREDENTIALS_SHOW: 'tenant.credentials.show';
  readonly TENANT_CREDENTIALS_CREATE: 'tenant.credentials.create';
  readonly TENANT_CREDENTIALS_UPDATE: 'tenant.credentials.update';
  readonly TENANT_CREDENTIALS_DELETE: 'tenant.credentials.delete';
  readonly TENANT_CREDENTIALS_SHOW_SECRETS: 'tenant.credentials.show_secrets';
  readonly TENANT_CREDENTIALS_REFRESH_OAUTH: 'tenant.credentials.refresh_oauth';
  readonly TENANT_CREDENTIALS_REFRESH_STATUS: 'tenant.credentials.refresh_status';
  readonly TENANT_DATASOURCES_ALL: 'tenant.datasources.all';
  readonly TENANT_DATASOURCES_SHOW: 'tenant.datasources.show';
  readonly TENANT_DATASOURCES_CREATE: 'tenant.datasources.create';
  readonly TENANT_DATASOURCES_UPDATE: 'tenant.datasources.update';
  readonly TENANT_DATASOURCES_DELETE: 'tenant.datasources.delete';
  readonly TENANT_DATASOURCES_ACTIVATE: 'tenant.datasources.activate';
  readonly TENANT_DATASOURCES_DEACTIVATE: 'tenant.datasources.deactivate';
  readonly TENANT_DATASOURCES_META_TEMPLATES_SHOW: 'tenant.datasources.meta_templates.show';
  readonly TENANT_DATASOURCE_TYPES_SHOW: 'tenant.datasource_types.show';
  readonly TENANT_SHARED_DATASOURCES_ALL: 'tenant.shared_datasources.all';
  readonly TENANT_SHARED_DATASOURCES_SHOW: 'tenant.shared_datasources.show';
  readonly TENANT_SHARED_DATASOURCES_CREATE: 'tenant.shared_datasources.create';
  readonly TENANT_SHARED_DATASOURCES_UPDATE: 'tenant.shared_datasources.update';
  readonly TENANT_SHARED_DATASOURCES_DELETE: 'tenant.shared_datasources.delete';
  readonly TENANT_TEMPLATES_ALL: 'tenant.templates.all';
  readonly TENANT_TEMPLATES_SHOW: 'tenant.templates.show';
  readonly TENANT_TEMPLATES_CREATE: 'tenant.templates.create';
  readonly TENANT_TEMPLATES_UPDATE: 'tenant.templates.update';
  readonly TENANT_TEMPLATES_DELETE: 'tenant.templates.delete';
  readonly TENANT_DATASOURCE_TEMPLATES_ALL: 'tenant.datasource_templates.all';
  readonly TENANT_DATASOURCE_TEMPLATES_SHOW: 'tenant.datasource_templates.show';
  readonly TENANT_DATASOURCE_TEMPLATES_CREATE: 'tenant.datasource_templates.create';
  readonly TENANT_DATASOURCE_TEMPLATES_UPDATE: 'tenant.datasource_templates.update';
  readonly TENANT_DATASOURCE_TEMPLATES_DELETE: 'tenant.datasource_templates.delete';
  readonly TENANT_DATASOURCE_TEMPLATES_ACTIVATE: 'tenant.datasource_templates.activate';
  readonly TENANT_DATASOURCE_TEMPLATES_DEACTIVATE: 'tenant.datasource_templates.deactivate';
  readonly TENANT_DATASOURCE_TEMPLATES_NOTIFY_DATA_IMPORTED: 'tenant.datasource_templates.notify_data_imported';
  readonly TENANT_DATASOURCE_BUCKET_GETFILE: 'tenant.datasource.bucket.getfile';
  readonly TENANT_DATASOURCE_FTP_USER_SHOW: 'tenant.datasource.ftpuser.show';
  readonly TENANT_DATASOURCE_FTP_USER_REGENERATE_PASSWORD: 'tenant.datasource.ftpuser.regeneratepassword';
  readonly TENANT_DATASOURCE_IMPORT_MAIL_SHOW: 'tenant.datasource.import_mail.show';
  readonly TENANT_DATASOURCE_IMPORT_MAIL_IMPORT: 'tenant.datasource.import_mail.import';
  readonly TENANT_DATASOURCE_IMPORT_MAIL_REGENERATE_EMAIL: 'tenant.datasource.import_mail.regenerate_email';
  readonly TENANT_DATASOURCE_NOTIFY_DATA_AVAILABLE: 'tenant.datasource.notify_data_available';
  readonly TENANT_DATASOURCE_MANUAL_RETRY: 'tenant.datasource.manual_retry';
  readonly TENANT_TENANTS_ALL: 'tenant.tenants.all';
  readonly TENANT_TENANTS_SHOW: 'tenant.tenants.show';
  readonly TENANT_TENANTS_CREATE: 'tenant.tenants.create';
  readonly TENANT_TENANTS_UPDATE: 'tenant.tenants.update';
  readonly TENANT_TENANTS_DELETE: 'tenant.tenants.delete';
  readonly TENANT_TENANTS_ACTIVATE: 'tenant.tenants.activate';
  readonly TENANT_TENANTS_DEACTIVATE: 'tenant.tenants.deactivate';
  readonly TENANT_TRANSLATIONS_SHOW: 'tenant.translations.show';
  readonly TENANT_AUDIT_LOGS_SHOW: 'tenant.audit_logs.show';
  readonly TENANT_EXPLORE_PRESET_ALL: 'tenant.explore_preset.all';
  readonly TENANT_EXPLORE_PRESET_SHOW: 'tenant.explore_preset.show';
  readonly TENANT_EXPLORE_PRESET_CREATE: 'tenant.explore_preset.create';
  readonly TENANT_EXPLORE_PRESET_UPDATE: 'tenant.explore_preset.update';
  readonly TENANT_EXPLORE_PRESET_DELETE: 'tenant.explore_preset.delete';
  readonly TENANT_EXPLORE_PRESET_NOTIFY_USE: 'tenant.explore_preset.notify_use';
  readonly TENANT_EXPLORE_PRESET_MY_SHOW: 'tenant.explore_preset.my.show';
  readonly TENANT_EXPLORE_PRESET_MY_CREATE: 'tenant.explore_preset.my.create';
  readonly TENANT_EXPLORE_PRESET_MY_UPDATE: 'tenant.explore_preset.my.update';
  readonly TENANT_EXPLORE_PRESET_MY_DELETE: 'tenant.explore_preset.my.delete';
  readonly TENANT_EXPLORE_PRESET_MY_NOTIFY_USE: 'tenant.explore_preset.my.notify_use';
  readonly TENANT_DASHBOARDS_ALL: 'tenant.dashboards.all';
  readonly TENANT_DASHBOARDS_SHOW: 'tenant.dashboards.show';
  readonly TENANT_REPORTS_ALL: 'tenant.reports.all';
  readonly TENANT_REPORTS_SHOW: 'tenant.reports.show';
  readonly TENANT_REPORTS_CREATE: 'tenant.reports.create';
  readonly TENANT_REPORTS_UPDATE: 'tenant.reports.update';
  readonly TENANT_REPORTS_DELETE: 'tenant.reports.delete';
  readonly TENANT_REPORTS_ACTIVATE: 'tenant.reports.activate';
  readonly TENANT_REPORTS_DEACTIVATE: 'tenant.reports.deactivate';
  readonly TENANT_CURRENCIES_ALL: 'tenant.currencies.all';
  readonly TENANT_CURRENCIES_SHOW: 'tenant.currencies.show';
  readonly NORMALIZATION_ALL: 'normalization.all';
  readonly NORMALIZATION_REPORTINGS_ALL: 'normalization.reportings.all';
  readonly NORMALIZATION_REPORTINGS_NORMALIZE: 'normalization.reportings.normalize';
  readonly NORMALIZATION_REPORTINGS_FETCH: 'normalization.reportings.fetch';
  readonly COLLECT_ALL: 'collect.all';
  readonly COLLECT_METRICS_ALL: 'collect.metrics.all';
  readonly COLLECT_METRICS_SHOW: 'collect.metrics.show';
  readonly COLLECT_DIMENSIONS_ALL: 'collect.dimensions.all';
  readonly COLLECT_DIMENSIONS_SHOW: 'collect.dimensions.show';
  readonly COLLECT_REPORTINGS_ALL: 'collect.reportings.all';
  readonly COLLECT_REPORTINGS_IMPORT: 'collect.reportings.import';
  readonly COLLECT_REPORTINGS_DELETE: 'collect.reportings.delete';
  readonly COLLECT_REPORTINGS_BACKUP: 'collect.reportings.backup';
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_ALL: 'collect.reportings.shared_datasource.all';
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_IMPORT: 'collect.reportings.shared_datasource.import';
  readonly COLLECT_REPORTINGS_SHARED_DATASOURCE_DELETE: 'collect.reportings.shared_datasource.delete';
  readonly REPORTINGS_ALL: 'reportings.all';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_ALL: 'reportings.custom_dimensions.all';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_SHOW: 'reportings.custom_dimensions.show';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_CREATE: 'reportings.custom_dimensions.create';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_UPDATE: 'reportings.custom_dimensions.update';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_DELETE: 'reportings.custom_dimensions.delete';
  readonly REPORTINGS_CUSTOM_DIMENSIONS_CHECK: 'reportings.custom_dimensions.check';
  readonly REPORTINGS_CUSTOM_METRICS_ALL: 'reportings.custom_metrics.all';
  readonly REPORTINGS_CUSTOM_METRICS_SHOW: 'reportings.custom_metrics.show';
  readonly REPORTINGS_CUSTOM_METRICS_CREATE: 'reportings.custom_metrics.create';
  readonly REPORTINGS_CUSTOM_METRICS_UPDATE: 'reportings.custom_metrics.update';
  readonly REPORTINGS_CUSTOM_METRICS_DELETE: 'reportings.custom_metrics.delete';
  readonly REPORTINGS_CUSTOM_METRICS_CHECK: 'reportings.custom_metrics.check';
  readonly REPORTINGS_REPORTING_ALL: 'reportings.reporting.all';
  readonly REPORTINGS_REPORTING_FIELDS_ALL: 'reportings.reporting.fields.all';
  readonly REPORTINGS_REPORTING_FIELDS_SHOW: 'reportings.reporting.fields.show';
  readonly REPORTINGS_REPORTING_DATA_ALL: 'reportings.reporting.data.all';
  readonly REPORTINGS_REPORTING_DATA_SHOW: 'reportings.reporting.data.show';
  readonly REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_ALL: 'reportings.reporting.google_data_studio.all';
  readonly REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_SHOW: 'reportings.reporting.google_data_studio.show';
  readonly REPORTINGS_REPORTING_INITIALIZE_FIELDS: 'reportings.reporting.initialize_fields';
  readonly REPORTINGS_STATISTICS_SHOW: 'reportings.statistics.show';
  readonly REPORTINGS_CACHE_CLEAR: 'reportings.cache.clear';
  readonly REPORTINGS_CACHE_WARMUP: 'reportings.cache.warmup';
  readonly REPORTINGS_FIELD_METAS_ALL: 'reportings.field_metas.all';
  readonly REPORTINGS_FIELD_METAS_SHOW: 'reportings.field_metas.show';
  readonly REPORTINGS_FIELD_METAS_UPDATE: 'reportings.field_metas.update';
  readonly REPORTINGS_OUTGOING_DATASHARE_ALL: 'reportings.outgoing_datashare.all';
  readonly REPORTINGS_OUTGOING_DATASHARE_SHOW: 'reportings.outgoing_datashare.show';
  readonly REPORTINGS_OUTGOING_DATASHARE_CREATE: 'reportings.outgoing_datashare.create';
  readonly REPORTINGS_OUTGOING_DATASHARE_UPDATE: 'reportings.outgoing_datashare.update';
  readonly REPORTINGS_OUTGOING_DATASHARE_DELETE: 'reportings.outgoing_datashare.delete';
  readonly EVENTS_ALL: 'events.all';
  readonly EVENTS_PUBLISH: 'events.publish';
  readonly EVENTS_LISTEN: 'events.listen';
  readonly MANAGEMENT_ALL: 'management.all';
  readonly MANAGEMENT_CHECK: 'management.check';
  readonly MANAGEMENT_RUN: 'management.run';
  readonly MANAGEMENT_IMPORT_JOB_SHOW: 'management.import_job.show';
  readonly MANAGEMENT_IMPORT_JOB_UPDATE: 'management.import_job.update';
  readonly MANAGEMENT_ERROR_DEBUG_INFO_SHOW: 'management.error_debug_info_show';
  readonly CURRENCY_ALL: 'currency.all';
  readonly CURRENCY_OVERVIEW: 'currency.overview';
  readonly CURRENCY_RUN: 'currency.run';
} = {
  ALL: 'all',
  TENANT_INDEPENDENT: 'tenant_independent',
  OFFLINE: 'offline',
  SECURITY_ALL: 'security.all',
  SECURITY_CLIENTS_ALL: 'security.clients.all',
  SECURITY_CLIENTS_SHOW: 'security.clients.show',
  SECURITY_CLIENTS_CREATE: 'security.clients.create',
  SECURITY_CLIENTS_UPDATE: 'security.clients.update',
  SECURITY_CLIENTS_DELETE: 'security.clients.delete',
  SECURITY_CLIENTS_SHOW_SECRETS: 'security.clients.show_secrets',
  SECURITY_OAUTH_CLIENTS_ALL: 'security.oauth_clients.all',
  SECURITY_OAUTH_CLIENTS_SHOW: 'security.oauth_clients.show',
  SECURITY_OAUTH_CLIENTS_CREATE: 'security.oauth_clients.create',
  SECURITY_OAUTH_CLIENTS_UPDATE: 'security.oauth_clients.update',
  SECURITY_OAUTH_CLIENTS_DELETE: 'security.oauth_clients.delete',
  SECURITY_OAUTH_CLIENTS_SHOW_SECRETS: 'security.oauth_clients.show_secrets',
  SECURITY_TENANTS_REGISTER: 'security.tenants.register',
  SECURITY_TENANTS_DELETE: 'security.tenants.delete',
  SECURITY_USERS_ALL: 'security.users.all',
  SECURITY_USERS_SHOW: 'security.users.show',
  SECURITY_USERS_CREATE: 'security.users.create',
  SECURITY_USERS_UPDATE: 'security.users.update',
  SECURITY_USERS_DELETE: 'security.users.delete',
  SECURITY_GROUPS_ALL: 'security.groups.all',
  SECURITY_GROUPS_SHOW: 'security.groups.show',
  SECURITY_GROUPS_CREATE: 'security.groups.create',
  SECURITY_GROUPS_UPDATE: 'security.groups.update',
  SECURITY_GROUPS_DELETE: 'security.groups.delete',
  SECURITY_SUBSCRIPTIONS_ALL: 'security.subscriptions.all',
  SECURITY_SUBSCRIPTIONS_SHOW: 'security.subscriptions.show',
  SECURITY_SUBSCRIPTIONS_CREATE: 'security.subscriptions.create',
  SECURITY_SUBSCRIPTIONS_UPDATE: 'security.subscriptions.update',
  SECURITY_SUBSCRIPTIONS_DELETE: 'security.subscriptions.delete',
  TENANT_ALL: 'tenant.all',
  TENANT_CREDENTIALS_ALL: 'tenant.credentials.all',
  TENANT_CREDENTIALS_SHOW: 'tenant.credentials.show',
  TENANT_CREDENTIALS_CREATE: 'tenant.credentials.create',
  TENANT_CREDENTIALS_UPDATE: 'tenant.credentials.update',
  TENANT_CREDENTIALS_DELETE: 'tenant.credentials.delete',
  TENANT_CREDENTIALS_SHOW_SECRETS: 'tenant.credentials.show_secrets',
  TENANT_CREDENTIALS_REFRESH_OAUTH: 'tenant.credentials.refresh_oauth',
  TENANT_CREDENTIALS_REFRESH_STATUS: 'tenant.credentials.refresh_status',
  TENANT_DATASOURCES_ALL: 'tenant.datasources.all',
  TENANT_DATASOURCES_SHOW: 'tenant.datasources.show',
  TENANT_DATASOURCES_CREATE: 'tenant.datasources.create',
  TENANT_DATASOURCES_UPDATE: 'tenant.datasources.update',
  TENANT_DATASOURCES_DELETE: 'tenant.datasources.delete',
  TENANT_DATASOURCES_ACTIVATE: 'tenant.datasources.activate',
  TENANT_DATASOURCES_DEACTIVATE: 'tenant.datasources.deactivate',
  TENANT_DATASOURCES_META_TEMPLATES_SHOW: 'tenant.datasources.meta_templates.show',
  TENANT_DATASOURCE_TYPES_SHOW: 'tenant.datasource_types.show',
  TENANT_SHARED_DATASOURCES_ALL: 'tenant.shared_datasources.all',
  TENANT_SHARED_DATASOURCES_SHOW: 'tenant.shared_datasources.show',
  TENANT_SHARED_DATASOURCES_CREATE: 'tenant.shared_datasources.create',
  TENANT_SHARED_DATASOURCES_UPDATE: 'tenant.shared_datasources.update',
  TENANT_SHARED_DATASOURCES_DELETE: 'tenant.shared_datasources.delete',
  TENANT_TEMPLATES_ALL: 'tenant.templates.all',
  TENANT_TEMPLATES_SHOW: 'tenant.templates.show',
  TENANT_TEMPLATES_CREATE: 'tenant.templates.create',
  TENANT_TEMPLATES_UPDATE: 'tenant.templates.update',
  TENANT_TEMPLATES_DELETE: 'tenant.templates.delete',
  TENANT_DATASOURCE_TEMPLATES_ALL: 'tenant.datasource_templates.all',
  TENANT_DATASOURCE_TEMPLATES_SHOW: 'tenant.datasource_templates.show',
  TENANT_DATASOURCE_TEMPLATES_CREATE: 'tenant.datasource_templates.create',
  TENANT_DATASOURCE_TEMPLATES_UPDATE: 'tenant.datasource_templates.update',
  TENANT_DATASOURCE_TEMPLATES_DELETE: 'tenant.datasource_templates.delete',
  TENANT_DATASOURCE_TEMPLATES_ACTIVATE: 'tenant.datasource_templates.activate',
  TENANT_DATASOURCE_TEMPLATES_DEACTIVATE: 'tenant.datasource_templates.deactivate',
  TENANT_DATASOURCE_TEMPLATES_NOTIFY_DATA_IMPORTED: 'tenant.datasource_templates.notify_data_imported',
  TENANT_DATASOURCE_BUCKET_GETFILE: 'tenant.datasource.bucket.getfile',
  TENANT_DATASOURCE_FTP_USER_SHOW: 'tenant.datasource.ftpuser.show',
  TENANT_DATASOURCE_FTP_USER_REGENERATE_PASSWORD: 'tenant.datasource.ftpuser.regeneratepassword',
  TENANT_DATASOURCE_IMPORT_MAIL_SHOW: 'tenant.datasource.import_mail.show',
  TENANT_DATASOURCE_IMPORT_MAIL_IMPORT: 'tenant.datasource.import_mail.import',
  TENANT_DATASOURCE_IMPORT_MAIL_REGENERATE_EMAIL: 'tenant.datasource.import_mail.regenerate_email',
  TENANT_DATASOURCE_NOTIFY_DATA_AVAILABLE: 'tenant.datasource.notify_data_available',
  TENANT_DATASOURCE_MANUAL_RETRY: 'tenant.datasource.manual_retry',
  TENANT_TENANTS_ALL: 'tenant.tenants.all',
  TENANT_TENANTS_SHOW: 'tenant.tenants.show',
  TENANT_TENANTS_CREATE: 'tenant.tenants.create',
  TENANT_TENANTS_UPDATE: 'tenant.tenants.update',
  TENANT_TENANTS_DELETE: 'tenant.tenants.delete',
  TENANT_TENANTS_ACTIVATE: 'tenant.tenants.activate',
  TENANT_TENANTS_DEACTIVATE: 'tenant.tenants.deactivate',
  TENANT_TRANSLATIONS_SHOW: 'tenant.translations.show',
  TENANT_AUDIT_LOGS_SHOW: 'tenant.audit_logs.show',
  TENANT_EXPLORE_PRESET_ALL: 'tenant.explore_preset.all',
  TENANT_EXPLORE_PRESET_SHOW: 'tenant.explore_preset.show',
  TENANT_EXPLORE_PRESET_CREATE: 'tenant.explore_preset.create',
  TENANT_EXPLORE_PRESET_UPDATE: 'tenant.explore_preset.update',
  TENANT_EXPLORE_PRESET_DELETE: 'tenant.explore_preset.delete',
  TENANT_EXPLORE_PRESET_NOTIFY_USE: 'tenant.explore_preset.notify_use',
  TENANT_EXPLORE_PRESET_MY_SHOW: 'tenant.explore_preset.my.show',
  TENANT_EXPLORE_PRESET_MY_CREATE: 'tenant.explore_preset.my.create',
  TENANT_EXPLORE_PRESET_MY_UPDATE: 'tenant.explore_preset.my.update',
  TENANT_EXPLORE_PRESET_MY_DELETE: 'tenant.explore_preset.my.delete',
  TENANT_EXPLORE_PRESET_MY_NOTIFY_USE: 'tenant.explore_preset.my.notify_use',
  TENANT_DASHBOARDS_ALL: 'tenant.dashboards.all',
  TENANT_DASHBOARDS_SHOW: 'tenant.dashboards.show',
  TENANT_REPORTS_ALL: 'tenant.reports.all',
  TENANT_REPORTS_SHOW: 'tenant.reports.show',
  TENANT_REPORTS_CREATE: 'tenant.reports.create',
  TENANT_REPORTS_UPDATE: 'tenant.reports.update',
  TENANT_REPORTS_DELETE: 'tenant.reports.delete',
  TENANT_REPORTS_ACTIVATE: 'tenant.reports.activate',
  TENANT_REPORTS_DEACTIVATE: 'tenant.reports.deactivate',
  TENANT_CURRENCIES_ALL: 'tenant.currencies.all',
  TENANT_CURRENCIES_SHOW: 'tenant.currencies.show',
  NORMALIZATION_ALL: 'normalization.all',
  NORMALIZATION_REPORTINGS_ALL: 'normalization.reportings.all',
  NORMALIZATION_REPORTINGS_NORMALIZE: 'normalization.reportings.normalize',
  NORMALIZATION_REPORTINGS_FETCH: 'normalization.reportings.fetch',
  COLLECT_ALL: 'collect.all',
  COLLECT_METRICS_ALL: 'collect.metrics.all',
  COLLECT_METRICS_SHOW: 'collect.metrics.show',
  COLLECT_DIMENSIONS_ALL: 'collect.dimensions.all',
  COLLECT_DIMENSIONS_SHOW: 'collect.dimensions.show',
  COLLECT_REPORTINGS_ALL: 'collect.reportings.all',
  COLLECT_REPORTINGS_IMPORT: 'collect.reportings.import',
  COLLECT_REPORTINGS_DELETE: 'collect.reportings.delete',
  COLLECT_REPORTINGS_BACKUP: 'collect.reportings.backup',
  COLLECT_REPORTINGS_SHARED_DATASOURCE_ALL: 'collect.reportings.shared_datasource.all',
  COLLECT_REPORTINGS_SHARED_DATASOURCE_IMPORT: 'collect.reportings.shared_datasource.import',
  COLLECT_REPORTINGS_SHARED_DATASOURCE_DELETE: 'collect.reportings.shared_datasource.delete',
  REPORTINGS_ALL: 'reportings.all',
  REPORTINGS_CUSTOM_DIMENSIONS_ALL: 'reportings.custom_dimensions.all',
  REPORTINGS_CUSTOM_DIMENSIONS_SHOW: 'reportings.custom_dimensions.show',
  REPORTINGS_CUSTOM_DIMENSIONS_CREATE: 'reportings.custom_dimensions.create',
  REPORTINGS_CUSTOM_DIMENSIONS_UPDATE: 'reportings.custom_dimensions.update',
  REPORTINGS_CUSTOM_DIMENSIONS_DELETE: 'reportings.custom_dimensions.delete',
  REPORTINGS_CUSTOM_DIMENSIONS_CHECK: 'reportings.custom_dimensions.check',
  REPORTINGS_CUSTOM_METRICS_ALL: 'reportings.custom_metrics.all',
  REPORTINGS_CUSTOM_METRICS_SHOW: 'reportings.custom_metrics.show',
  REPORTINGS_CUSTOM_METRICS_CREATE: 'reportings.custom_metrics.create',
  REPORTINGS_CUSTOM_METRICS_UPDATE: 'reportings.custom_metrics.update',
  REPORTINGS_CUSTOM_METRICS_DELETE: 'reportings.custom_metrics.delete',
  REPORTINGS_CUSTOM_METRICS_CHECK: 'reportings.custom_metrics.check',
  REPORTINGS_REPORTING_ALL: 'reportings.reporting.all',
  REPORTINGS_REPORTING_FIELDS_ALL: 'reportings.reporting.fields.all',
  REPORTINGS_REPORTING_FIELDS_SHOW: 'reportings.reporting.fields.show',
  REPORTINGS_REPORTING_DATA_ALL: 'reportings.reporting.data.all',
  REPORTINGS_REPORTING_DATA_SHOW: 'reportings.reporting.data.show',
  REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_ALL: 'reportings.reporting.google_data_studio.all',
  REPORTINGS_REPORTING_GOOGLE_DATA_STUDIO_SHOW: 'reportings.reporting.google_data_studio.show',
  REPORTINGS_REPORTING_INITIALIZE_FIELDS: 'reportings.reporting.initialize_fields',
  REPORTINGS_STATISTICS_SHOW: 'reportings.statistics.show',
  REPORTINGS_CACHE_CLEAR: 'reportings.cache.clear',
  REPORTINGS_CACHE_WARMUP: 'reportings.cache.warmup',
  REPORTINGS_FIELD_METAS_ALL: 'reportings.field_metas.all',
  REPORTINGS_FIELD_METAS_SHOW: 'reportings.field_metas.show',
  REPORTINGS_FIELD_METAS_UPDATE: 'reportings.field_metas.update',
  REPORTINGS_OUTGOING_DATASHARE_ALL: 'reportings.outgoing_datashare.all',
  REPORTINGS_OUTGOING_DATASHARE_SHOW: 'reportings.outgoing_datashare.show',
  REPORTINGS_OUTGOING_DATASHARE_CREATE: 'reportings.outgoing_datashare.create',
  REPORTINGS_OUTGOING_DATASHARE_UPDATE: 'reportings.outgoing_datashare.update',
  REPORTINGS_OUTGOING_DATASHARE_DELETE: 'reportings.outgoing_datashare.delete',
  EVENTS_ALL: 'events.all',
  EVENTS_PUBLISH: 'events.publish',
  EVENTS_LISTEN: 'events.listen',
  MANAGEMENT_ALL: 'management.all',
  MANAGEMENT_CHECK: 'management.check',
  MANAGEMENT_RUN: 'management.run',
  MANAGEMENT_IMPORT_JOB_SHOW: 'management.import_job.show',
  MANAGEMENT_IMPORT_JOB_UPDATE: 'management.import_job.update',
  MANAGEMENT_ERROR_DEBUG_INFO_SHOW: 'management.error_debug_info_show',
  CURRENCY_ALL: 'currency.all',
  CURRENCY_OVERVIEW: 'currency.overview',
  CURRENCY_RUN: 'currency.run'
};
// DO NOT MODIFY THIS COMMENT autogenerated end
type StringScopesKey = keyof typeof StringScopes;
export type StringScope = (typeof StringScopes)[StringScopesKey];

export function isStringScope(value: unknown): value is StringScope {
  return typeof value === 'string' && (Object.values(StringScopes) as string[]).includes(value);
}

export function assertStringScope(value: unknown): asserts value is StringScope {
  if (!isStringScope(value)) {
    assertStringNotEmpty(value);
    throw new Error('Value ' + value + ' is not a valid StringScope');
  }
}

export function validStringScope(value: unknown): StringScope {
  assertStringScope(value);
  return value;
}
